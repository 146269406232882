<template>
  <div class="relative overflow-hidden">
    <SvgShapeGearSharp class="hidden md:block absolute text-yellow w-[13.625vw] h-[13.625vw] top-[17.5vw] md:top-[40vw] left-[-4.8vw]" />
    <SvgShapeCrescent class="rotate-[135deg] absolute top-[1vw] md:top-[-3vw] right-0 text-turquoise h-[40vw] w-[20vw] md:h-[20vw] md:w-[10vw]" />
    <SvgShapeHalfCircle class="hidden md:block absolute text-pink h-[8vw] w-[16vw] top-[3vw] left-[30vw]" />


    <Container v-if="content.content.banner "
               class="pt-[24vw] md:pt-[8vw] -mb-[24vw] md:-mb-[5vw] relative z-10"
    >
      <CardPromoPrivacy v-if="promoContent"
                        v-bind="promoContent.promo"
      />
    </Container>

    <BlogContent :content="content.content.items"
                 :title="content.title"
                 class="pb-[16vw] md:pb-[7.5vw]"
    />
  </div>
</template>

<script setup lang="ts">
import { useAsyncData, queryContent } from '#imports';
import { computed } from 'vue';

type TemplateHomepageProps = {
    content: any
}

defineProps<TemplateHomepageProps>();

const { data } = await useAsyncData('general', () => queryContent('globals', 'general').findOne());

const promoContent = computed(() => {
  if (data && data.value && data.value.promo) {
    return data.value;
  }

  return false;
});
</script>